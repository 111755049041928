import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Logo from "../Logo/Logo";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <Logo />

      <div className="footerItemContainer">
        <div className="footerItem">
          <FontAwesomeIcon icon={faWhatsapp} className="footerIcon" />
          <a href="https://wa.me/+541165186458 ">+54 11 65186458 </a>
          <a href="https://wa.me/+541130780398">+54 11 30780398 </a>
        </div>
        <div className="footerItem">
          <FontAwesomeIcon icon={faEnvelope} className="footerIcon" />
          <p>comercial@blinksalud.com</p>
        </div>
        <div className="footerItem">
          <FontAwesomeIcon icon={faLocationDot} className="footerIcon" />
          <p>Av. Córdoba 937, CABA</p>
        </div>
        <div className="footerItem">
          <FontAwesomeIcon icon={faInstagram} className="footerIcon" />
          <a href="https://www.instagram.com/blinksalud">@blinksalud</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
