import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FaqAccordion.css";

const generalQuestions = [
  {
    question:
      "¿Cómo funciona el registro y el perfil de usuario en la aplicación?",
    answer:
      "Los usuarios (afiliados, asesores, y compañías) pueden registrarse y crear un perfil personalizado, incluyendo información básica de contacto. Los clientes (perfil afiliado) tendrán acceso a su credencial digital, gestión de turnos y acceso a su nube de historia clínica, donde se generará un perfil digital con detalles médicos y preferencias de cobertura.",
  },
  {
    question: "¿Cómo puedo buscar y comparar planes de salud en la aplicación?",
    answer:
      "La aplicación permite a los socios asesores buscar y comparar diferentes planes de salud ofrecidos por las distintas compañías de salud, utilizando filtros como precio, tipo de cobertura, clínica de preferencia, etc.",
  },
  {
    question:
      "¿Es posible contratar cobertura de salud directamente desde la aplicación?",
    answer:
      "Sí, los asesores pueden contratar un plan de salud directamente desde la aplicación, con opciones de pago seguras.",
  },
  {
    question:
      "¿Qué funciones relacionadas con la atención médica ofrece la aplicación para los afiliados?",
    answer:
      "La aplicación permite únicamente a los usuarios afiliados acceder a su historial médico y gestionar consultas y tratamientos en línea con médicos de confianza.",
  },
  {
    question:
      "¿Qué tipo de notificaciones y recordatorios envía la aplicación?",
    answer:
      "La aplicación envía notificaciones y recordatorios (push) personalizados para informar observaciones en ventas, pagos de comisiones, derivación de leads, etc.",
  },
];

const FaqAccordion = () => {
  return (
    <div className="faqAccordion">
      <h1>Preguntas Frecuentes</h1>
      {generalQuestions.map((faq, index) => {
        return (
          <Accordion
            style={{ margin: 0 }}
            key={`faq ${index}`}
            className="accordionItem"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              style={{ padding: "10px" }}
            >
              <h3>{faq.question}</h3>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              {faq.answer}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default FaqAccordion;
