import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalStateContext";
import "./Logo.css";

const Logo = () => {
  const { setIsDisplayed } = useContext(GlobalContext);

  return (
    <div>
      <Link
        onClick={() => {
          document.querySelector("body").style.overflow = "visible";
          setIsDisplayed(false);
        }}
        to={"/"}
      >
        <svg
          width="164"
          height="40"
          viewBox="0 0 82 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8271 13.513L8.27872 16.0613C7.53971 16.791 6.54208 17.1986 5.50357 17.1954C4.46507 17.1921 3.47001 16.7782 2.73559 16.0439C2.00117 15.3097 1.58702 14.3147 1.58354 13.2762C1.58005 12.2377 1.98751 11.24 2.71699 10.5008L5.26657 7.95123C5.40482 7.80181 5.47982 7.60464 5.47581 7.40111C5.47181 7.19759 5.38911 7.00352 5.24509 6.85966C5.10107 6.71579 4.90691 6.63331 4.70338 6.62953C4.49985 6.62575 4.30276 6.70096 4.15349 6.83937L1.60513 9.38774C0.576691 10.4211 0.000142492 11.8203 0.0019574 13.2782C0.0037723 14.7362 0.583802 16.1339 1.61481 17.1647C2.64582 18.1956 4.04361 18.7754 5.50156 18.777C6.9595 18.7786 8.35856 18.2018 9.3918 17.1732L11.9402 14.6248C12.0129 14.551 12.0701 14.4633 12.1084 14.367C12.1467 14.2707 12.1653 14.1676 12.1631 14.064C12.161 13.9604 12.1381 13.8582 12.0958 13.7636C12.0535 13.6689 11.9927 13.5837 11.917 13.513C11.7675 13.3782 11.5733 13.3037 11.372 13.3037C11.1708 13.3037 10.9766 13.3782 10.8271 13.513Z"
            fill="#34E8CA"
          />
          <path
            d="M17.0437 1.62546C16.535 1.11002 15.9288 0.701104 15.2603 0.422607C14.5918 0.14411 13.8745 0.00161167 13.1504 0.00343525C11.6937 0.006508 10.2964 0.580523 9.25824 1.60227L6.70744 4.15185C6.56919 4.30127 6.49419 4.49844 6.4982 4.70197C6.50221 4.9055 6.5849 5.09956 6.72892 5.24342C6.87294 5.38729 7.0671 5.46977 7.27063 5.47355C7.47416 5.47734 7.67125 5.40213 7.82052 5.26371L10.3689 2.71535C10.7331 2.34626 11.1668 2.05286 11.6449 1.85203C12.123 1.6512 12.6361 1.54692 13.1546 1.54518C13.6732 1.54344 14.1869 1.64427 14.6664 1.84189C15.1458 2.0395 15.5814 2.32998 15.9481 2.69662C16.3148 3.06325 16.6054 3.4988 16.8031 3.97819C17.0009 4.45757 17.1018 4.97133 17.1002 5.48988C17.0986 6.00844 16.9944 6.52155 16.7937 6.99969C16.5929 7.47782 16.2996 7.91153 15.9306 8.27586L13.3823 10.8206C13.2393 10.9691 13.1603 11.1678 13.1623 11.374C13.1643 11.5801 13.247 11.7773 13.3928 11.9231C13.5386 12.0689 13.7358 12.1516 13.9419 12.1536C14.1481 12.1556 14.3468 12.0766 14.4953 11.9336L17.0437 9.38406C17.5553 8.87586 17.9614 8.27147 18.2385 7.60569C18.5156 6.93991 18.6582 6.2259 18.6582 5.50476C18.6582 4.78362 18.5156 4.0696 18.2385 3.40382C17.9614 2.73805 17.5553 2.13366 17.0437 1.62546Z"
            fill="#34E8CA"
          />
          <path
            d="M11.8245 5.84391L6.00767 11.6595C5.93314 11.731 5.87342 11.8164 5.83192 11.911C5.79042 12.0056 5.76795 12.1074 5.7658 12.2106C5.76364 12.3139 5.78185 12.4165 5.81937 12.5127C5.85689 12.609 5.91299 12.6968 5.98448 12.7714C6.05596 12.8459 6.14142 12.9056 6.23598 12.9471C6.33055 12.9886 6.43236 13.0111 6.5356 13.0132C6.63885 13.0154 6.74151 12.9972 6.83772 12.9597C6.93393 12.9221 7.02181 12.866 7.09633 12.7946L7.11952 12.7714L12.9595 6.93258C13.0325 6.85834 13.0898 6.77017 13.128 6.67333C13.1663 6.57648 13.1846 6.47294 13.182 6.36886C13.1794 6.26479 13.1558 6.1623 13.1128 6.0675C13.0698 5.9727 13.0081 5.88753 12.9315 5.81706C12.7769 5.68242 12.5776 5.61051 12.3728 5.61548C12.1679 5.62045 11.9723 5.70194 11.8245 5.84391Z"
            fill="#34E8CA"
          />
          <path
            d="M39.8655 17.9299C39.1393 17.1536 38.7891 15.9771 38.7891 14.4246V0.00341797H42.5445V13.8864C42.5677 14.6187 42.5799 15.1984 42.8301 15.4999C43.1962 15.8758 43.4037 16.0113 43.9541 16.0113C44.3801 16.0113 44.7975 15.827 45.1746 15.7013L46.0009 18.2557C45.7007 18.5059 44.8243 19.1101 43.0717 19.1101C41.6426 19.132 40.5917 18.7317 39.8655 17.9299Z"
            fill="white"
          />
          <path
            d="M53.4355 18.7905V5.64587H57.191V7.47659C57.302 7.0958 57.8171 6.14627 58.6177 5.67028C59.4184 5.19429 60.3703 4.94531 61.4724 4.94531C63.2006 4.94531 64.5525 5.44571 65.5281 6.44651C66.5045 7.44852 66.9805 9.17551 66.9805 11.1539V18.7905H63.225V11.5799C63.225 10.5278 62.9748 9.65149 62.4744 9.07543C61.974 8.49936 61.398 8.2992 60.496 8.2992C60.0572 8.28698 59.6207 8.36765 59.2151 8.53591C58.8096 8.70418 58.4442 8.95624 58.1429 9.27558C57.5168 9.92732 57.191 10.778 57.191 11.8301V18.7868L53.4355 18.7905Z"
            fill="white"
          />
          <path
            d="M75.6831 11.7093L82.0027 18.7881H77.3088L72.6136 13.1568V18.7905H68.8594V0.012207H72.6136L72.627 6.51493L72.6136 10.3399L76.369 5.64595H81.063L75.6831 11.7093Z"
            fill="white"
          />
          <path
            d="M51.1832 5.646H47.4277V18.7906H51.1832V5.646Z"
            fill="white"
          />
          <path
            d="M49.3057 4.51822C50.55 4.51822 51.5588 3.50952 51.5588 2.26522C51.5588 1.02091 50.55 0.012207 49.3057 0.012207C48.0614 0.012207 47.0527 1.02091 47.0527 2.26522C47.0527 3.50952 48.0614 4.51822 49.3057 4.51822Z"
            fill="white"
          />
          <path
            d="M36.0854 8.21751C35.5725 7.18632 34.7728 6.32516 33.7824 5.73749C32.7698 5.15444 31.62 4.85234 30.4517 4.8624C29.3752 4.8624 28.4489 5.08737 27.6726 5.53733C27.1479 5.85233 26.6462 6.20431 26.1714 6.5906V0H22.416V18.782H26.1714C26.1714 18.0802 26.1873 16.8573 26.1873 16.8573C26.1873 16.8573 26.7975 17.8556 27.6519 18.3573C28.5522 18.8655 29.5717 19.125 30.6054 19.1091C31.7397 19.1394 32.8589 18.8443 33.8307 18.2586C34.8025 17.6729 35.5862 16.8211 36.0891 15.804C36.6395 14.7263 36.9141 13.2996 36.9141 11.8985C36.9141 10.4974 36.6359 9.26834 36.0854 8.21751ZM29.933 15.8126C29.1892 15.8126 28.4622 15.592 27.8439 15.1788C27.2255 14.7656 26.7435 14.1784 26.4589 13.4913C26.1743 12.8042 26.0998 12.0481 26.2449 11.3187C26.39 10.5892 26.7481 9.91921 27.274 9.39332C27.7999 8.86743 28.4699 8.5093 29.1994 8.36421C29.9288 8.21912 30.6849 8.29358 31.372 8.57819C32.0591 8.8628 32.6464 9.34476 33.0595 9.96314C33.4727 10.5815 33.6933 11.3085 33.6933 12.0523C33.6933 13.0496 33.2971 14.006 32.5919 14.7112C31.8867 15.4164 30.9303 15.8126 29.933 15.8126Z"
            fill="white"
          />
        </svg>
      </Link>
    </div>
  );
};

export default Logo;
